/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Typography, Popconfirm } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import moment from "moment";
import {
  setPatientsList,
  setPendingListAsync,
} from "../../../redux/patients/actions";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import PatientsList from "./PatientsList";
import emptyValues from "./emptyValues";
import VisitInformations from "../VisitInformations";
//import FluidsNumber from "./FluidsNumber";
import {
  parseVisitName,
  structureFields,
  structureFieldsNested,
} from "../../../helpers/functions";
import Clinical from "../../../components/medicalRecord/clinical";
import DrugForm from "./drugForm";
import BloodSample from "../BloodSample";
import { createVisitAsync } from "../../../redux/visits/actions";
import "./stylesIndex.css";
import Inclusion from "../../../components/medicalRecord/inclusion";
import Examinations from "../../../components/medicalRecord/examinations";
import AntecedentsExams from "../../../components/medicalRecord/antecedents";
import SignesExams from "../../../components/medicalRecord/signes";
import TraitementExams from "../../../components/medicalRecord/traitements";
import Questionnaire from "../../../components/medicalRecord/questionnaire";
import ShowPatientData from "../../../components/medicalRecord/showPatientDatya";
import Measure from "../../../components/medicalRecord/measures";

const { Step } = Steps;
const { Title } = Typography;

const AddVisit = ({
  contentIsLoading,
  setPendingListAsync,
  visit,
  patientsList,
  createVisitAsync,
  setPatientsList,
  closeAdd,
}) => {
  const [current, setCurrent] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [patient, selectPatient] = useState(null);
  const [values, setValues] = useState(emptyValues);
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [inclusionForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();
  const [examinationsForm] = Form.useForm();
  const [antecedentsForm] = Form.useForm();
  const [signesForm] = Form.useForm();
  const [traitementsForm] = Form.useForm();
  const [dateVisiteForm] = Form.useForm();
  const [formComplete, setFormComplete] = useState(false);
  const [questionnaireForm] = Form.useForm();
  const [measureForm] = Form.useForm();

  const [eligiblePatient, setEligibilePatient] = useState(true);
  const [inclusion, setInclusion] = useState({
    //age: ageValue > 18 && ageValue < 55 ? "Non" : "Oui",
    ic: "",
    pavd: "",
  });
  const [exclusion, setExclusion] = useState({
    anotherTrial: "",
    //age: ageValue < 18 || ageValue > 55 ? "Non" : "Oui",
    distance: "",
    dic: "",
    ipe: "",
    ilFormulaireConsentement: "",
  });
  // const onChange = (name, value) => {
  //   setValues({ ...values, [name]: value });
  // };
  const [transport, setTransport] = useState(null);
  const [MC, setMC] = useState(null);
  const [PCI, setPCI] = useState(null);
  const [IDM, setIDM] = useState(null);
  const [CABG, setCABG] = useState(null);
  const [PC, setPC] = useState(null);
  const [ICP, setICP] = useState(null);
  const [AFA, setAFA] = useState(null);
  const [AVC, setAVC] = useState(null);
  const [MVAP, setMVAP] = useState(null);
  const [DIABETE, setDIABETE] = useState(null);
  const [HA, setHA] = useState(null);
  const [CANCER, setCANCER] = useState(null);
  const [BPCO, setBPCO] = useState(null);
  const [MRC, setMRC] = useState(null);
  const [MDF, setMDF] = useState(null);
  const [DEPRESSION, setDEPRESSION] = useState(null);
  const [DT, setDT] = useState(null);
  /** SIGNES */
  const [RP, setRP] = useState(null);
  const [ER, setER] = useState(null);
  const [EE, setEE] = useState(null);
  const [TN, setTN] = useState(null);
  const [RTE, setRTE] = useState(null);
  const [ANG, setANG] = useState(null);
  const [JPV, setJPV] = useState(null);
  const [TBC, setTBC] = useState(null);
  const [RS, setRS] = useState(null);
  const [OP, setOP] = useState(null);
  const [DPN, setDPN] = useState(null);
  const [ORT, setORT] = useState(null);
  const [CXR, setCXR] = useState(null);
  /** END SIGNES */
  /**TREATEMENTS */
  const [ICD, setICD] = useState(null);
  const [BB, setBB] = useState(null);
  const [ACE, setACE] = useState(null);
  const [ARB, setARB] = useState(null);
  const [ARN, setARN] = useState(null);
  const [ARM, setARM] = useState(null);
  const [IV, setIV] = useState(null);
  const [SGL, setSGL] = useState(null);
  const [DA, setDA] = useState(null);
  const [DTH, setDTH] = useState(null);
  const [ST, setST] = useState(null);
  const [TA, setTA] = useState(null);

  const [didValsBioPass, setDidValsBioPass] = useState(true);

  /** END TREATEMENTS */
  /** BIOLOGICAL VALS */
  const [NA, setNA] = useState(null);
  const [K, setK] = useState(null);
  const [Ca, setCa] = useState(null);
  const [Mg, setMg] = useState(null);
  const [CO3, setCO3] = useState(null);
  const [Cl, setCl] = useState(null);
  const [eGFR, seteGFR] = useState(null);
  const [cholestérol, setCholestérol] = useState(null);
  const [Glycémie, setGlycémie] = useState(null);
  const [hemoglobineHB, setHemoglobineHB] = useState(null);
  const [hemoglobine, setHemoglobine] = useState(null);
  const [Troponine, setTroponine] = useState(null);

  const [HIS, setHIS] = useState(null);

  const [listMessage, setListMessage] = useState([]);

  /**END BIO */
  const staticMeasureVals = [
    {
      name: "IVSd",
      min: 6,
      max: 23,
      unit: "mm",
    },
    {
      name: "LVIDd",
      min: 37,
      max: 100,
      unit: "mm",
    },
    {
      name: "LVIDs",
      min: 22,
      max: 75,
      unit: "mm",
    },
    {
      name: "LVPWd",
      min: 6,
      max: 23,
      unit: "mm",
    },
    {
      name: "LVEDV",
      min: 50,
      max: 300,
      unit: "mL",
    },
    {
      name: "LVESV",
      min: 18,
      max: 115,
      unit: "mL",
    },
    {
      name: "LVEF",
      min: 15,
      max: 75,
      unit: "%",
    },
    {
      name: "LAESV",
      min: 40,
      max: 300,
      unit: "g/l",
    },
    {
      name: "RVIDd",
      min: 20,
      max: 100,
      unit: "mm",
    },
    {
      name: "TAPSE",
      min: 2,
      // max: 20,
      unit: "mm",
    },
    {
      name: "TR",
      min: 0,
      max: 20,
      unit: "m/s",
    },
    {
      name: "LVEDVA2C",
      min: 50,
      max: 300,
      unit: "mL",
    },
    {
      name: "LVESVA2C",
      min: 18,
      max: 115,
      unit: "mL",
    },
    {
      name: "LVEFA2C",
      min: 15,
      max: 75,
      unit: "%",
    },
    {
      name: "LAESVA2C",
      min: 40,
      max: 300,
      unit: "mL",
    },
  ];

  const fnCheckMeasuresVals = (value, name) => {
    const objFromStatic = staticMeasureVals.find((e) => e.name === name);
    const check = listMessage.some((e) => e.name === name);
    if ((value > objFromStatic.max || value < objFromStatic.min) && !check) {
      setDidValsBioPass(false);
      setListMessage([
        ...listMessage,
        {
          name,
          message:
            objFromStatic.min && objFromStatic.max
              ? `Merci de vérifier la valeur ${name} qui devrait être entre ${objFromStatic.min} et ${objFromStatic.max} ${objFromStatic.unit}.`
              : `Merci de vérifier la valeur ${name} qui devrait être supérieure à ${objFromStatic.min} ${objFromStatic.unit}.`,
        },
      ]);
    } else {
      if (value <= objFromStatic.max && value >= objFromStatic.min) {
        const deleteList = listMessage.filter((e) => e.name !== name);
        setListMessage(deleteList);
      }
    }
  };

  const fnCheckUsuellVals = (value, name) => {
    const objFromStatic = staticUsuelleVals.find((e) => e.name === name);
    const check = listMessage.some((e) => e.name === name);
    if ((value > objFromStatic.max || value < objFromStatic.min) && !check) {
      setDidValsBioPass(false);
      if (name === "Troponine") {
        setListMessage([
          ...listMessage,
          {
            name,
            message: `Merci de vérifier la valeur ${name} qui devrait être inférieur à ${objFromStatic.max} ${objFromStatic.unit} `,
          },
        ]);
      } else {
        setListMessage([
          ...listMessage,
          {
            name,
            message:
              objFromStatic.min && objFromStatic.max
                ? `Merci de vérifier la valeur ${name} qui devrait être entre ${objFromStatic.min} et ${objFromStatic.max} ${objFromStatic.unit}.`
                : `Merci de vérifier la valeur ${name} qui devrait être supérieure à ${objFromStatic.min} ${objFromStatic.unit}.`,
          },
        ]);
      }
    } else {
      if (value <= objFromStatic.max && value >= objFromStatic.min) {
        const deleteList = listMessage.filter((e) => e.name !== name);
        setListMessage(deleteList);
      }
    }
  };
  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "ICD":
        setICD(value);
        break;
      case "BB":
        setBB(value);
        break;
      case "ACE":
        setACE(value);
        break;
      case "ARB":
        setARB(value);
        break;
      case "ARN":
        setARN(value);
        break;
      case "ARM":
        setARM(value);
        break;
      case "IV":
        setIV(value);
        break;
      case "SGL":
        setSGL(value);
        break;
      case "TA":
        setTA(value);
        break;
      case "DA":
        setDA(value);
        break;
      case "DTH":
        setDTH(value);
        break;
      case "ST":
        setST(value);
        break;
      case "ORT":
        setORT(value);
        break;
      case "CXR":
        setCXR(value);
        break;
      case "JPV":
        setJPV(value);
        break;
      case "TBC":
        setTBC(value);
        break;
      case "RS":
        setRS(value);
        break;
      case "OP":
        setOP(value);
        break;
      case "DPN":
        setDPN(value);
        break;
      case "TN":
        setTN(value);
        break;
      case "RTE":
        setRTE(value);
        break;
      case "ANG":
        setANG(value);
        break;
      case "RP":
        setRP(value);
        break;
      case "ER":
        setER(value);
        break;
      case "EE":
        setEE(value);
        break;
      case "PCI":
        setPCI(value);
        break;
      case "IDM":
        setIDM(value);
        break;
      case "CABG":
        setCABG(value);
        break;
      case "transport":
        setTransport(value);
        break;
      case "MC":
        setMC(value);
        break;
      case "PC":
        setPC(value);
        break;
      case "ICP":
        setICP(value);
        break;
      case "AFA":
        setAFA(value);
        break;
      case "AVC":
        setAVC(value);
        break;
      case "MVAP":
        setMVAP(value);
        break;
      case "DIABETE":
        setDIABETE(value);
        break;
      case "HA":
        setHA(value);
        break;
      case "CANCER":
        setCANCER(value);
        break;
      case "BPCO":
        setBPCO(value);
        break;
      case "MRC":
        setMRC(value);
        break;
      case "MDF":
        setMDF(value);
        break;
      case "DEPRESSION":
        setDEPRESSION(value);
        break;
      case "DT":
        setDT(value);
        break;
      //case "age":
      case "ic":
      case "pavd":
        setInclusion({ ...inclusion, [name]: value });
        break;
      case "anotherTrial":
      //case "age":
      case "distance":
      case "dic":
      case "ipe":
      case "ilFormulaireConsentement":
        setExclusion({ ...exclusion, [name]: value });
        break;
      case "NA":
        setNA(value);
        fnCheckUsuellVals(value, name);
        break;
      case "K":
        setK(value);
        fnCheckUsuellVals(value, name);
        break;
      case "Ca":
        setCa(value);
        fnCheckUsuellVals(value, name);
        break;
      case "Mg":
        setMg(value);
        fnCheckUsuellVals(value, name);
        break;
      case "CO3":
        setCO3(value);
        fnCheckUsuellVals(value, name);
        break;
      case "Cl":
        setCl(value);
        fnCheckUsuellVals(value, name);
        break;
      case "eGFR":
        seteGFR(value);
        fnCheckUsuellVals(value, name);
        break;
      case "cholestérol":
        setCholestérol(value);
        fnCheckUsuellVals(value, name);
        break;
      case "Glycémie":
        setGlycémie(value);
        fnCheckUsuellVals(value, name);
        break;
      case "hemoglobineHB":
        setHemoglobineHB(value);
        fnCheckUsuellVals(value, name);
        break;
      case "hemoglobine":
        setHemoglobine(value);
        fnCheckUsuellVals(value, name);
        break;
      case "Troponine":
        setTroponine(value);
        fnCheckUsuellVals(value, name);
        break;
      case "HasInsuffisanceCardiaque":
        setHIS(value);
        break;
      default:
        if (staticMeasureVals.some((e) => e.name === name)) {
          fnCheckMeasuresVals(value, name);
        }
        break;
    }
  };
  function confirm(e) {
    handleNext();
  }

  function cancel(e) {
    console.log(e);
    // alert('Click on No');
  }
  useEffect(() => {
    if (current === 3) {
      setFormComplete(false);
      const didInclusionFilled = Object.values(inclusion).every(
        (x) => x === "Oui"
      );
      const didExclusionFilled = Object.values(exclusion).every(
        (x) => x === "Non"
      );
      if (didInclusionFilled && didExclusionFilled) {
        setFormComplete(true);
      }
    }
  }, [inclusion, exclusion]);

  useEffect(() => {
    console.log("***************************************");
    console.log(current === 1 && !eligiblePatient);
    console.log("currnet", current);
    console.log(!eligiblePatient);
    console.log("***************************************");
  });

  useEffect(() => {
    if (formComplete) {
      setEligibilePatient(!eligiblePatient);
    } else {
      // setEligibilePatient(true);
      setEligibilePatient(false);
    }
  }, [formComplete]);

  useEffect(() => {
    setPendingListAsync(visit, closeAdd);
    return () => {
      setPatientsList([]);
    };
  }, []);

  useEffect(
    () => () => {
      setPatientsList([]);
      closeAdd();
    },
    [visit]
  );

  useEffect(() => {
    if (formSubmitting) {
      createVisitAsync({ ...values, patient, visitNumber: visit });

      setFormSubmitting(false);
      setValues(null);
      setValues(emptyValues);
      closeAdd();
    }
    if (current === 0 && patient) {
      selectPatient(null);
    }
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    } else {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        case 4:
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    }
  }, [current, formSubmitting]);
  const staticUsuelleVals = [
    {
      name: "NA",
      min: 135,
      max: 145,
      unit: "mmol/l",
    },
    {
      name: "K",
      min: 3.4,
      max: 5.1,
      unit: "mmol/l",
    },
    {
      name: "Ca",
      min: 2.2,
      max: 2.6,
      unit: "mmmol/l",
    },
    {
      name: "Mg",
      min: 0.7,
      max: 1.1,
      unit: "mmol/l",
    },
    {
      name: "Cl",
      min: 95,
      max: 110,
      unit: "Cl",
    },
    {
      name: "CO3",
      min: 22,
      max: 27,
      unit: "mmol/l",
    },
    {
      name: "eGFR",
      min: 1.3,
      max: 2.0,
      unit: "mL/min /1.73 m²",
    },
    {
      name: "cholestérol",
      min: 0.7,
      max: 1.1,
      unit: "g/l",
    },
    {
      name: "Glycémie",
      min: 13,
      max: 18,
      unit: "",
    },
    {
      name: "hemoglobineHB",
      min: 4,
      max: 6.7,
      unit: "",
    },
    {
      name: "hemoglobine",
      min: 4,
      max: 6.7,
      unit: "",
    },
    {
      name: "Troponine",
      max: 0.6,
      min: 0,
      unit: "μg/L",
    },
  ];

  const handleNext = async (data) => {
    try {
      let vals;
      if (visit === "j0" || visit === "j7") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            // visitInformationsForm.setFieldsValue({
            //   admissionDate: {
            //     date: data.decision.nextVisitDate.value,
            //     time: moment(),
            //   },
            // });
            break;
          case 3:
            const dateVisiteVal = await dateVisiteForm.getFieldValue(
              "dateVisite"
            );
            const inclusionVals = await inclusionForm.validateFields();
            const exclusionVals = await exclusionForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, {
                dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
              }),
              inclusion: structureFields(values.inclusion, inclusionVals),
              exclusion: structureFields(values.exclusion, exclusionVals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            setCurrent(current + 1);
            break;
          case 1:
            vals = await visitInformationsForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          case 4:
            vals = await examinationsForm.validateFields();
            setValues({
              ...values,
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 5:
            console.log("after examinaton", values);
            vals = await antecedentsForm.validateFields();
            setValues({
              ...values,
              antecedents: structureFields(values.antecedents, vals),
            });
            setCurrent(current + 1);
            break;
          case 6:
            console.log("after 5", values);
            vals = await signesForm.validateFields();
            setValues({
              ...values,
              signes: structureFields(values.signes, vals),
            });
            setCurrent(current + 1);
            break;
          case 7:
            console.log("after 6", values);
            vals = await traitementsForm.validateFields();
            setValues({
              ...values,
              traitements: structureFields(values.traitements, vals),
            });
            //debugger
            setCurrent(current + 1);
            break;
          case 8:
            console.log(values);
            //debugger
            vals = {
              dateNextRDVCardio: drugForm.getFieldValue("dateNextRDVCardio"),
              dateNextRDVCardioHeure: drugForm.getFieldValue(
                "dateNextRDVCardioHeure"
              ),
            };
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else if (visit === "j1") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await clinicalForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await questionnaireForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await clinicalForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await measureForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = await questionnaireForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      // Nvm
    }
  };
  const showButton = () => {
    if (visit === "j0") {
      if (current === 0) return "";
      if (current < 7 && current !== 0) return "Suivant";
      return "Terminer";
    }
    if (visit === "j1") {
      if (current < 2) return "Suivant";
      return "Terminer";
    }
    if (visit === "j2") {
      if (current < 3) return "Suivant";
      return "Terminer";
    }
  };

  const showStep = () => {
    if (visit === "j0") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 3:
          return (
            <Inclusion
              inclusionForm={inclusionForm}
              exclusionForm={exclusionForm}
              dateVisiteForm={dateVisiteForm}
              values={values}
              onUpdate={onUpdateValidation}
            />
          );
        case 1:
          return (
            <div style={{ maxWidth: 500 }}>
              <VisitInformations
                values={values}
                form={visitInformationsForm}
                visit={visit}
                onUpdate={onUpdateValidation}
                transport={transport}
                isAdd={true}
              />
              {/* <Clinical
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
              /> */}
            </div>
          );
        case 2:
          return <ShowPatientData patient={patient} visit={visit} />;
        case 4:
          return (
            <div>
              <Examinations
                values={values}
                form={examinationsForm}
                onUpdate={onUpdateValidation}
                // NA={NA}
                // K={K}
                // Ca={Ca}
                // Mg={Mg}
                // CO3={CO3}
                // Cl={Cl}
                // eGFR={eGFR}
                // cholesterol={cholesterol}
                // Glycémie={Glycémie}
                // hemoglobineHB={hemoglobineHB}
                // hemoglobine={hemoglobine}
                // Troponine={Troponine}
                listMessage={listMessage}
                isAdd={true}
              />
            </div>
          );
        case 5:
          console.log("case 4 ", values);
          return (
            <div>
              <AntecedentsExams
                values={values}
                form={antecedentsForm}
                onUpdate={onUpdateValidation}
                PC={PC}
                MC={MC}
                ICP={ICP}
                AFA={AFA}
                AVC={AVC}
                MVAP={MVAP}
                DIABETE={DIABETE}
                HA={HA}
                CANCER={CANCER}
                BPCO={BPCO}
                MRC={MRC}
                MDF={MDF}
                DEPRESSION={DEPRESSION}
                DT={DT}
                IDM={IDM}
                PCI={PCI}
                CABG={CABG}
              />
            </div>
          );
        case 6:
          console.log("case 5 ", values);
          return (
            <div>
              <SignesExams
                values={values}
                form={signesForm}
                onUpdate={onUpdateValidation}
                RP={RP}
                ER={ER}
                EE={EE}
                TN={TN}
                RTE={RTE}
                ANG={ANG}
                JPV={JPV}
                TBC={TBC}
                RS={RS}
                OP={OP}
                DPN={DPN}
                ORT={ORT}
                CXR={CXR}
              />
            </div>
          );
        case 7:
          return (
            <div>
              <TraitementExams
                values={values}
                form={traitementsForm}
                onUpdate={onUpdateValidation}
                ICD={ICD}
                BB={BB}
                ACE={ACE}
                ARB={ARB}
                ARN={ARN}
                ARM={ARM}
                IV={IV}
                SGL={SGL}
                DA={DA}
                DTH={DTH}
                ST={ST}
                TA={TA}
              />
            </div>
          );
        case 8:
          return <DrugForm form={drugForm} visit={visit} patient={patient} />;
        default:
          return <div />;
      }
    } else if (visit === "j1") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div style={{ minWidth: "100%" }}>
              <Clinical
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
                visit={visit}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ minWidth: "100%" }}>
              <Questionnaire
                values={values}
                questionnaireForm={questionnaireForm}
                visit={visit}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div style={{ maxWidth: 900 }}>
              <Clinical
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
                visit={visit}
                HIS={HIS}
                onUpdate={onUpdateValidation}
                isAdd={true}
              />
            </div>
          );
        case 2:
          return (
            <Measure
              values={values}
              measuresForm={measureForm}
              visit={visit}
              isAdd={true}
              onUpdate={onUpdateValidation}
              listMessage={listMessage}
            />
          );
        case 3:
          return (
            <div style={{ maxWidth: 900 }}>
              <Questionnaire
                values={values}
                questionnaireForm={questionnaireForm}
                visit={visit}
              />
            </div>
          );
        default:
          return <div />;
      }
    }
  };
  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(visit, true)}
        thirdSectionName="Ajout d'une visite"
        handleGoBack={closeAdd}
      />
      <Spin spinning={contentIsLoading}>
        <Card>
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium stepsHeader"
          >
            <Step title="Identification" />

            {/* <Step disabled={current < 2} title="Nombre de fluides" /> */}

            {/* {(visit !== "j0" && visit !== "j7") ||
              visit === "j1" ||
              (visit === "j8" && (
                <Step disabled={current < 2} title="Prélèvement" />
              ))} */}

            {visit === "j2" && (
              <>
                <Step disabled={current < 2} title="Informations V2" />
                <Step
                  disabled={current < 3}
                  title="Paramètres de l’échocardiographie"
                />
                <Step disabled={current < 4} title="Questionnaire" />
              </>
            )}

            {visit === "j1" ? (
              <>
                <Step disabled={current < 2} title="Informations V1" />
                <Step disabled={current < 3} title="Questionnaire" />
              </>
            ) : null}

            {visit === "j0" || visit === "j7" ? (
              <>
                <Step disabled={current < 1} title=" Informations" />
                <Step disabled={current < 2} title="Données personnelles" />
                <Step disabled={current < 3} title="Inclusion" />
                <Step disabled={current < 4} title="Résultats biologiques" />
                <Step disabled={current < 5} title="Antécédents Médicaux" />
                <Step disabled={current < 6} title="Signes et symptômes" />
                <Step disabled={current < 7} title="Traitements" />
                <Step disabled={current < 8} title="Finalisation" />
              </>
            ) : null}
          </Steps>

          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
          {current === 3 && !eligiblePatient && visit === "j0" ? (
            <Popconfirm
              title="Certain critéres ne permettent pas l'inclusion de ce patient. Etes vous sur de vouloir continuer?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Oui"
              cancelText="Non"
              className="m-top-medium m-bottom-medium center-button"
            >
              <Button
                type="primary"
                className="m-top-medium m-bottom-medium center-button"
              >
                Suivant
              </Button>
            </Popconfirm>
          ) : (current === 4 && visit === "j0" && didValsBioPass === false) ||
            (current === 2 && visit === "j2" && didValsBioPass === false) ? (
            <>
              <Popconfirm
                title="Merci de vérifier les valeurs. Etes vous sur de vouloir continuer?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Oui"
                cancelText="Non"
                className="m-top-medium m-bottom-medium center-button"
              >
                <Button
                  type="primary"
                  className="m-top-medium m-bottom-medium center-button"
                >
                  Suivant
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button
              onClick={handleNext}
              type="primary"
              className="m-top-medium m-bottom-medium center-button"
            >
              {showButton()}
            </Button>
          )}
        </Card>
      </Spin>
    </div>
  );
};

AddVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  setPendingListAsync: PropTypes.func.isRequired,
  visit: PropTypes.string.isRequired,
  patientsList: PropTypes.array.isRequired,
  createVisitAsync: PropTypes.func.isRequired,
  setPatientsList: PropTypes.func.isRequired,
  closeAdd: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  patientsList: state.patients.patientsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPendingListAsync,
      createVisitAsync,
      setPatientsList,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AddVisit);
