import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Divider, Typography } from "antd";
import DynamicField from "../DynamicField";
import { calculateBMI } from "../../helpers/functions";
import "./styles.css";

const { Text } = Typography;

const Questionnaire = ({ questionnaireForm, values, onUpdate, visit }) => {
  return (
    <div className="inclusionContainer">
      <Form
        style={{ marginTop: 30 }}
        form={questionnaireForm}
        className="inclusion-form"
      >
        {visit !== "j3" && (
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Questionnaire patient </strong>
            </div>
            {["remis", "finished", "recover"].map((k, index) => {
              return (
                <>
                  <div>
                    <DynamicField
                      key={k}
                      field={values[k]}
                      onUpdate={onUpdate}
                      fieldFamily="clinical"
                    />
                  </div>
                </>
              );
            })}
          </div>
        )}
        <Card title="Liste questionnaire participant" className="add-antecedent-card">
          {visit === "j1" || visit === "j2"
            ? [
                "peniblie",
                "explicationDebut",
                "mal",
                "explicationFin",
                "examenDomicile",
                "echoComfort",
                "refaireExam",
                "satisfaction",
              ].map((k) => (
                <>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="questionnaire"
                    //radio={true}
                  />

                  <Divider style={{ margin: "12px 0" }} />
                </>
              ))
            : ""}
          {visit === "j2"
            ? ["BRefaireExam", "trajetPenibilite", "salleAttente"].map((k) => (
                <>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="questionnaire"
                    //radio={true}
                  />

                  <Divider style={{ margin: "12px 0" }} />
                </>
              ))
            : ""}

          {visit === "j3"
            ? [
                "v31",
                "v32",
                "v33",
                "v34",
                "v35",
                "v36",
                "v37",
                "v38",
                "v39",
                "v40",
                "v41",
                "v42",
                "v43",
                "v44",
                "v45",
                "v46",
                "v47",
                "v47H",
                "v48",
                "v49",
              ].map((k) => (
                <>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="questionnaire"
                    //radio={true}
                  />

                  <Divider style={{ margin: "12px 0" }} />
                </>
              ))
            : ""}
        </Card>
      </Form>
    </div>
  );
};

Questionnaire.propTypes = {
  questionnaireForm: PropTypes.object.isRequired,
  visit: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

Questionnaire.defaultProps = {
  onUpdate: () => {},
};

export default Questionnaire;
