import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import DynamicField from "../../../components/DynamicField";
import { getAllVisitsByPatientAsync } from "../../../redux/visits/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

const Call = ({
  form,
  values,
  onUpdate,
  visit,
  data,
  visitsHistory,
  getAllVisitsByPatientAsync,
  dateVisitV0,
  dateVisitV3
}) => {
  const [visitData, setVisitData] = useState();
  useEffect(() => {
    getAllVisitsByPatientAsync(data._id);
  }, [data]);

  useEffect(() => {
    if (visitsHistory) {
      visitsHistory.find((e) => e.visitNumber === visit && setVisitData(e));
    }
    if(visitsHistory && visit === "j3" ){
      visitsHistory.find((e) => e.visitNumber === "j2" && setVisitData(e));
    }
  }, [visitsHistory, data]);

  const [diffDate, setDiffDate] = useState();

  useEffect(() => {
    if (dateVisitV0 && visitData) {
      const d1 = moment(visitData.dateNextRDVCardio.value.split("-"));
      const d2 = moment(dateVisitV0.split("/").reverse());
      let days = d2.diff(d1, "days");
      setDiffDate(days);
    }

  }, [dateVisitV0]);

  useEffect(() => {
    if(dateVisitV3){
      const d1 = moment(visitData.dateVisite.value.split("/").reverse());
      const d2 = moment(dateVisitV3.split("/").reverse());
      let days = d2.diff(d1, "days");
      setDiffDate(days);
    }
  }, [dateVisitV3]);


  return (
    <Form style={{ marginTop: 30, minWidth: "100%" }} form={form}>
      <strong>Informations du participant</strong>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        {(data.phoneFirst || data.phoneFirst !== undefined) && (
          <p>{`${data.phoneFirst.label} :  ${data.phoneFirst.value}`}</p>
        )}
        {(data.phoneSecond && data.phoneSecond.value !== undefined) && (
          <p>{`${data.phoneSecond.label} :  ${data.phoneSecond.value}`}</p>
        )}
        {(data.phoneThird && data.phoneThird.value !== undefined) && (
          <p> {`${data.phoneThird.label} :  ${data.phoneThird.value}`}</p>
        )}
        {(data.address || data.address !== undefined) && (
          <p>{`${data.address.label} :  ${data.address.value}`}</p>
        )}
        <p></p>
      </div>
      {visit === "j0" ? (
        <div>
          <div style={{ marginBottom: 30 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>{visitData && visitData.dateNextRDVCardio.label} </strong>
              {visitData && visitData.dateNextRDVCardio.value}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>
                {visitData && visitData.dateNextRDVCardioHeure.label}{" "}
              </strong>
              {visitData && visitData.dateNextRDVCardioHeure.value}
            </div>
          </div>
          <strong>Date prévue de la visite V1 à domicile </strong>
          <DynamicField
            field={values.visitDate}
            onUpdate={onUpdate}
            radio={true}
            fieldFamily="call"
          />
           {
            diffDate > 5 && <div>
              <strong style={{ color: "red"}}>possibilité de prise de RDV : maximum 5 jours avant la visite pour l’écho</strong>
            </div>
          }
          <strong>Date de l’appel à la réception de la notification </strong>
          <DynamicField
            field={values.callDate}
            onUpdate={onUpdate}
            fieldFamily="call"
          />
        </div>
      ) : (
        <>
          <div>
            <strong>Date d'appel de la visite V3</strong>
            <DynamicField
              key={"callDate"}
              field={values["callDate"]}
              onUpdate={onUpdate}
              fieldFamily="call"
              radio={true}
            />
          </div>
          {
            diffDate < 6 && <div>
              <strong style={{ color: "red"}}>Date doit etre, 6jours après la visite V2</strong>
            </div>
          }
          <div>
            <strong>Questionnaire de satisfaction patient</strong>
            <DynamicField
              key={"questionnaire"}
              field={values["questionnaire"]}
              onUpdate={onUpdate}
              fieldFamily="call"
            />
          </div>
        </>
      )}
    </Form>
  );
};

Call.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  showHeightWeight: PropTypes.bool,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
};

Call.defaultProps = {
  onUpdate: () => {},
};

const stateToProps = (state) => ({
  visitsHistory: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllVisitsByPatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(Call);
